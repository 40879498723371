let routeName = 'module->Individual->ProfileModule';

export const module = {
  info: {
    fullName: 'Individual-ProfileModule',
    namespace: 'Individual',
    name: 'ProfileModule',
    caption: 'ProfileModule',
    routeName: 'module->Individual->ProfileModule',
    viewGroup: 'system'
  },
  vuexModules: ['api', 'state'],
  mainRoute: '/cpanel',
  sideMenu: {
    topSeparator: false,
    caption: 'ProfileModule',
    icon: 'el-icon-folder',
    routeName: 'control',
    /*children: [
      {
        caption: 'Create Entity',
        icon: 'el-icon-plus',
        routeName: 'create'
      },
      {
        caption: 'List Entities',
        icon: 'el-icon-more-outline',
        routeName: 'list->page',
        routeParams: { listPage: 1}
      }
    ]*/
  },
  router: {
    mainRouteName: 'portal-cpanel',
    routes: {
      path: 'ProfileModule',
      name: routeName,
      component: () => import('./View.vue'),
      children: [
        {
          path: 'control',
          name: routeName + '->control',
          component: () => import('./control/View'),
        }
      ]
    }
  }
}

export default module
